<template>
  <div class="container">
    <div class="gutter-example">
      <div class="gutter-box left mr-20">
        <a-input-search v-model="keyword" placeholder="支持搜索成员、部门或角色" style="width: 100%" @search="onSearch"/>
        <a-tabs v-if="!isSearch" v-model="activeKey" default-active-key="framework" class="tabs" @change="callback">
          <a-tab-pane key="framework" tab="企业架构">
            <div>
              <a-button type="dashed" style="width: 100%" class="mb-10" @click="addMember">
                <a-icon type="plus-circle" theme="filled"/>
                创建成员
              </a-button>
              <div v-for="(item, index) in memberTypeList" :key="index" class="user-list p-0-15"
                   :class="selectMember.id === item.id?'active':''" @click="getDetail(item, 'member', '')">
                <div class="list-item">
                  <span>
                    <!--<a-icon :type="item.memberTypeId == '0'?'user':'pie-chart'" class="mr-10" />-->
                    {{ item.memberTypeName }}</span>
                  <span>{{ userNum[item.typeName] }}</span>
                </div>
                <!--启用人数/启用上限-->
                <!--<div v-if="item.memberTypeId == '0'">-->
                <!--  <a-tooltip>-->
                <!--    <template slot="title">-->
                <!--      <div>-->
                <!--        <span class="mr-10">已启用 / 启用上限</span>{{ item.sum }}/{{ item.maxLimit }}-->
                <!--      </div></template>-->
                <!--    <a-progress stroke-linecap="round" stroke-color="#e6a23c" :stroke-width="4" :show-info="false" :percent="(item.sum/item.maxLimit)*100" />-->
                <!--  </a-tooltip>-->
                <!--</div>-->
              </div>
            </div>
            <a-divider style="margin: 10px 0;"/>
            <div>
              <a-button type="dashed" style="width: 100%" @click="addDepartment()">
                <a-icon type="plus-circle" theme="filled"/>
                添加部门
              </a-button>
              <Department
                v-if="departmentVisible"
                :tree-data="treeData"
                :selected-keys="selectedKeys"
                @changeSelectedKeys="changeSelectedKeys"
                @getDetail="(item)=> {
                selectMember.id = ''
                getDetail(item, 'department', '')}"
              />
            </div>
            <div/>
          </a-tab-pane>
          <a-tab-pane key="role" tab="角色" force-render>
            <div>
              <a-button type="dashed" style="width: 100%" class="mb-10" @click="addRole">
                <a-icon type="plus-circle" theme="filled"/>
                添加角色
              </a-button>
              <div class="role-list">
                <div v-for="(item, index) in roleList" :key="index" class="role-list-item p-0-15"
                     :class="selectRole.roleId === item.roleId?'active':''" @click="getDetail(item, 'role', '')">
                  <a-icon class="mr-10" :type="item.type"/>
                  {{ item.roleName }}
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
        <div v-if="isSearch" class="search-list">
          <div>
            <div class="title">成员</div>
            <div
              class="left-item item-member pl-15"
              :class="selectId === '1'?'active':''"
              @click="getDetail({memberTypeName: '相关成员', memberTypeId: '0', typeName: 'userAll'}, 'member', '1')"
            >
              <span><a-icon type="user"/>相关成员</span>
              <span class="mr-10">{{ userListNum }}</span>
            </div>
          </div>
          <div v-if="searchData.memberList && searchData.memberList.length > 0">
            <div class="title">部门</div>
            <div
              v-for="(item, index) in searchData.memberList"
              :key="index"
              class="left-item pl-15"
              :class="selectId === item.departmentId?'active':''"
              @click="getDetail(item, 'department', item.departmentId)"
            >
              {{ item.departmentName }}
            </div>
          </div>
          <div v-if="searchData.roleList && searchData.roleList.length > 0">
            <div class="title">角色</div>
            <div
              v-for="(item, index) in searchData.roleList"
              :key="index"
              class="left-item pl-15"
              :class="selectId === item.roleId?'active':''"
              @click="getDetail(item, 'role', item.roleId)"
            >
              {{ item.roleName }}
            </div>
          </div>
        </div>
      </div>

      <div class="gutter-box right">
        <!-- 用户列表 -->
        <RoleTable
          v-if="Object.keys(listData).length > 0"
          :list-type="listType"
          :list-data="listData"
          :locale-text="localeText"
          :pagination="pagination"
          :parent-data="parentData"
          @changePosition="changePosition"
          @memberDetail="memberDetail"
          @changeStatus="changeStatus"
          @shiftOut="shiftOut"
          @resetPasswords="resetPasswords"
          @addChildren="addChildren"
          @editDepartment="editDepartment"
          @deleteDepartment="deleteDepartment"
          @setPermissions="setPermissions"
          @onChange="onChange"
          @deleteRole="deleteRole"
          @editRole="editRole"
          @deleteUser="deleteUser"
          @userAdd="userAdd"
        />
      </div>
      <!-- 添加部门 -->
      <AddDepartment
        v-if="addDepartmentVisible"
        :add-department-visible="addDepartmentVisible"
        :edit-type="editType"
        :department-data="departmentData"
        :parent-data="parentData"
        @closeModal="closeModal"
        @operationSuccess="operationSuccess"
      />
      <!-- 添加角色 -->
      <AddRole
        v-if="addRoleVisible"
        :add-role-visible="addRoleVisible"
        :role-data="roleData"
        :detail-type="detailType"
        :parent-data="parentData"
        @addRoleSuccess="addRoleSuccess"
        @closeRoleModal="closeRoleModal"
      />
      <!-- 详情和编辑弹框 -->
      <MemberDetail
        v-if="detailVisible"
        :detail-visible="detailVisible"
        :detail-data="detailData"
        :role-type-list="roleTypeList"
        :role-list="roleList"
        :dept-list="deptList"
        :is-edit="isEdit"
        :parent-data="parentData"
        @changeEdit="changeEdit"
        @editSuccess="editSuccess"
        @editMember="editMember"
        @openDetail="openDetail"
      />
      <!-- 添加成员 -->
      <MemberAdd
        v-if="addVisible"
        :add-visible="addVisible"
        :add-data="addData"
        :role-list="roleList"
        :dept-list="deptList"
        :parent-data="parentData"
        @addMemberSuccess="addMemberSuccess"
        @addCloseChange="addCloseChange"
      />
      <!-- 配置权限 -->
      <Permissions
        v-if="permissionsVisible"
        :permissions-visible="permissionsVisible"
        :permissions-tree-data="permissionsTreeData"
        @closePermissions="closePermissions"
      />
    </div>
    <a-modal
      v-model="passwordVisible"
      title="新密码"
      width="350px"
      :footer="null"
      :maskClosable="false"
      @cancel="passwordVisible = false"
    >
      <div class="modal-content">
        <div class="content-password">
          <p>
            <span>密码：</span>
            <span class="password-value">{{ newPassword }}</span>
          </p>
          <a-button type="primary" size="small" @click="doCopy(newPassword)" >复制</a-button>
        </div>
        <p class="content-hint">密码已重置，请保存好新密码</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Department from '@/components/structure/department.vue'
import AddDepartment from '@/components/structure/addDepartment.vue'
import AddRole from '@/components/structure/addRole.vue'
import RoleTable from '@/components/structure/roleTable.vue'
import MemberDetail from '@/components/structure/memberDetail'
import MemberAdd from '@/components/structure/memberAdd'
import Permissions from '@/components/structure/permissions'
import {
  // userBaseInfo,
  departmentList,
  deptUserList,
  roleList,
  roleDelete,
  useDisable,
  departmentDelete,
  roleUserList,
  departmentManagerList,
  departmentBases,
  userAmount,
  useDetail,
  moveDepartmentUser,
  moveRoleUser,
  userDelete,
  setPosition,
  resetPassword
} from '@/api/user'
import { recursionHandleTree } from "@/utils/util.js";

const permissionsTreeData = [
  {
    title: '0-0',
    key: '0-0',
    children: [
      {
        title: '0-0-0',
        key: '0-0-0',
        children: [
          {title: '0-0-0-0', key: '0-0-0-0'},
          {title: '0-0-0-1', key: '0-0-0-1'},
          {title: '0-0-0-2', key: '0-0-0-2'}
        ]
      },
      {
        title: '0-0-1',
        key: '0-0-1',
        children: [
          {title: '0-0-1-0', key: '0-0-1-0'},
          {title: '0-0-1-1', key: '0-0-1-1'},
          {title: '0-0-1-2', key: '0-0-1-2'}
        ]
      },
      {
        title: '0-0-2',
        key: '0-0-2'
      }
    ]
  },
  {
    title: '0-1',
    key: '0-1',
    children: [
      {title: '0-1-0-0', key: '0-1-0-0'},
      {title: '0-1-0-1', key: '0-1-0-1'},
      {title: '0-1-0-2', key: '0-1-0-2'}
    ]
  },
  {
    title: '0-2',
    key: '0-2'
  }
]
export default {
  name: 'Structure',
  components: {
    Department,
    AddDepartment,
    AddRole,
    RoleTable,
    MemberDetail,
    MemberAdd,
    Permissions
  },
  data() {
    return {
      activeKey: 'framework',
      memberTypeList: [
        { id: '1', memberTypeName: '全部成员', memberTypeId: '0', typeName: 'userAll', num: 4, sum: 4, maxLimit: 10 },
        { id: '3', memberTypeName: '有效成员', memberTypeId: '1', typeName: 'enableUser', num: 3, sum: 6, maxLimit: 10 },
        { id: '2', memberTypeName: '已禁用成员', memberTypeId: '2', typeName: 'disableUser', num: 1, sum: 6, maxLimit: 10 }
        // { id: '102', memberTypeName: '未分配成员', memberTypeId: '1', num: 3, sum: 6, maxLimit: 10 },
        // { id: '103', memberTypeName: '未接收邀请成员', memberTypeId: '2', num: 2, sum: 6, maxLimit: 10 },
      ],
      selectMember: {},
      selectDepartment: {},
      selectRole: {},
      selectedKeys: [],
      addDepartmentVisible: false,
      addRoleVisible: false,
      roleList: [],
      roleTypeList: {},
      listType: 'member',
      listData: {},
      positionData: { '0': '主管', '1': '成员' },
      detailVisible: false,
      detailData: {},
      roleData: {
        roleName: ''
      },
      isSearch: false,
      keyword: '',
      userListNum: '',
      permissionsVisible: false,
      permissionsTreeData: [],
      departmentData: {
        departmentName: ''
      },
      localeText: {
        emptyText: '暂无数据'
      },
      empty: '',
      selectId: '',
      deptForm: {
        isEnable: 0,
        departmentId: '',
        keyword: ''
      },
      pagination: {
        current: 1,
        defaultCurrent: 1,
        pageSize: 10,
        defaultPageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100'],
        // hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        total: 100,
        showTotal: (total) => `共 ${total} 条`
      },
      treeData: [],
      departmentVisible: false,
      addVisible: false,
      addData: {
        userCode: '',
        enterpriseId: '',
        userName: '',
        email: '',
        password: '',
        nickName: '',
        portraitFileId: '',
        isEnable: '',
        tel: '',
        landline: '',
        sex: '',
        address: '',
        remark: '',
        departmentIds: [
          { departmentId: '', departmentName: '', path: '', positionType: '' }
        ],
        roleIds: []

      },
      editType: false,
      detailType: '',
      deptList: [],
      parentData: {
        enterpriseId: '',
        departmentId: '',
        departmentName: '',
        parentDepartmentId: '',
        memberTypeId: '',
        memberTypeName: '',
        roleId: '',
        roleName: ''
      },
      userNum: {},
      searchData: {
        memberList: [],
        roleList: []
      },
      isEdit: false,
      operationType: '',
      passwordVisible: false,
      newPassword: ''
    }
  },
  watch: {
    keyword(value) {
      const ms = 500
      clearTimeout(this.timeID)
      this.timeID = setTimeout(() => {
        this.keywordChange(value)
      }, ms)
    }
  },
  async created() {
    await this.getUserAmount()
    this.getMemberUserList('')
    this.getDepartmentList(this.keyword, '')
  },
  mounted() {
    this.getDetail(this.memberTypeList[0], 'member', '')
    this.selectMember = this.memberTypeList[0]
  },
  methods: {
    keywordChange(value) {
      if (value === '') {
        this.isSearch = false
        this.selectId = ''
        this.getMemberUserList('')
      } else {
        this.isSearch = true
        this.selectId = ''
        this.parentData.memberTypeName = '相关成员'
        this.listType = 'member'
        this.onSearch(value)
      }
    },
    // 成员数量
    getUserAmount() {
      userAmount({ enterpriseId: '' }).then((res) => {
        if (res['code'] !== 0) {
          this.$message.error(res['message'])
          return
        }
        this.userNum = res.data
      })
    },
    // 成员用户列表
    getMemberUserList(bol) {
      const formData = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        param: { isEnable: bol, departmentId: '', keyword: this.keyword, enterpriseId: '' },
        orders: [{ field: 'user_id', desc: true }]
      }
      deptUserList(formData).then((res) => {
        if (res['code'] !== 0) {
          this.$message.error(res['message'])
          return
        }
        if (this.keyword) {
          this.userListNum = res.data.list.length
        }
        this.listData = res.data
        this.pagination.current = res.data['pageNum']
        this.pagination.pageSize = res.data['pageSize']
        this.pagination.total = res.data['total']
      })
    },
    // 企业部门列表
    getDepartmentList(keyword, type) {
      let data = {
        keyword: keyword,
        enterpriseId: ''
      }
      departmentList(data).then((res) => {
        this.departmentVisible = true
        if (res['code'] !== 0) {
          this.$message.error(res['message'])
          return
        }
        switch (type) {
          case '':
            if (keyword) {
              this.searchData.memberList = res.data.list
            } else {
              this.treeData = res.data.list
            }
            break
          case 'edit':
            if (keyword) {
              this.searchData.memberList = res.data.list
            } else {
              this.treeData = res.data.list
              this.setSelectedKeys(res.data.list, this.selectDepartment.departmentId )
            }
            break
          default:
        }
        recursionHandleTree(this.treeData, 'list', 'isLeaf')
      })
    },
    setSelectedKeys(list, id) {
      list.forEach((item) => {
        if (item.departmentId === id ) {
          this.selectDepartment = item
          this.parentData.departmentId = this.selectDepartment.departmentId
          this.parentData.departmentName = this.selectDepartment.departmentName
          this.getDetail(this.selectDepartment, 'department', this.selectDepartment.departmentId)
        } else {
          if(item.list && item.list.length > 0) {
            this.setSelectedKeys(item.list , id)
          }
        }
      })
    },
    // 部门用户列表
    getDeptUserList(deptForm) {
      const formData = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        param: {
          isEnable: deptForm.isEnable,
          departmentId: deptForm.departmentId,
          keyword: deptForm.keyword,
          enterpriseId: ''
        },
        orders: [{
          field: 'user_id',
          desc: true
        }]
      }
      deptUserList(formData).then((res) => {
        if (res['code'] !== 0) {
          this.$message.error(res['message'])
          return
        }
        this.listData = res.data
        this.pagination.current = res.data['pageNum']
        this.pagination.pageSize = res.data['pageSize']
        this.pagination.total = res.data['total']
      })
    },
    // 获取角色列表
    getRoleList(keyword, type) {
      let data = {
        keyword: keyword,
        enterpriseId: ''
      }
      roleList(data).then((res) => {
        if (res['code'] !== 0) {
          this.$message.error(res['message'])
          return
        }
        this.roleList = res.data
        if (this.activeKey === 'role' && res.data && res.data.length === 0 && type !== 'list') {
          this.selectRole = {}
          this.parentData.roleId = ''
          this.parentData.roleName = ''
          this.listType = 'role'
          this.listData = {
            list: []
          }
          this.setLocaleText('暂无数据')
          return
        }
        this.roleTypeList = this.dataProcessing.listFormat(res.data, 'roleId', 'roleName', 'object')
        if (!keyword) {
          switch (type) {
            case 'detail':
              this.selectRole = this.roleList
              this.getDetail(this.roleList[0], 'role', '')
              break
            case 'add':
              this.addCloseChange(true)
              break
            case 'edit':
              this.roleList.forEach((item) => {
                if (item.roleId === this.selectRole.roleId) {
                  this.selectRole = item
                  this.parentData.roleId = item.roleId
                  this.parentData.roleName = item.roleName
                  this.getDetail(item, 'role', '')
                }
              })
              break
            case 'delete':
              break
            default:
          }
        } else {
          this.searchData.roleList = res.data
        }
      })
    },
    // 搜索
    onSearch(value) {
      this.getMemberUserList('')
      this.getDepartmentList(value, '')
      this.getRoleList(value, '')
      this.selectId = '1'
    },
    // 打开添加部门弹框
    addDepartment() {
      this.editType = false
      this.addDepartmentVisible = true
      this.departmentData = {
        departmentName: ''
      }
    },
    // 关闭添加部门弹框
    closeModal(bol) {
      this.addDepartmentVisible = bol
    },
    // 操作部门成功-- 新增部门、编辑
    operationSuccess(isEdit) {
      if (this.keyword) {
        this.selectId = '1'
        this.renewalData()
      }
      if (isEdit) {
        this.getDepartmentList('', 'edit')
      } else {
        this.getDepartmentList('', '')
      }
    },
    // 打开添加角色弹框
    addRole() {
      this.detailType = 'add'
      this.roleData = {
        roleName: ''
      }
      this.addRoleVisible = true
    },
    // 关闭添加角色弹框
    closeRoleModal(bol) {
      this.addRoleVisible = bol
    },
    // 添加角色成功
    addRoleSuccess(bol) {
      if (bol) {
        this.getRoleList('', 'edit')
      } else {
        this.getRoleList('', '')
      }
    },
    // 编辑角色
    editRole(data) {
      this.detailType = 'edit'
      this.roleData = {
        roleId: data.roleId,
        roleName: data.roleName
      }
      this.addRoleVisible = true
    },
    // 删除
    deleteUser(record) {
      const that = this
      this.$confirm({
        content: '是否删除该角色成员？',
        onOk() {
          const data = {
            isDelete: 1,
            enterpriseId: '',
            userIds: [record.userId]
          }
          userDelete(data).then((res) => {
            if (res['code'] !== 0) {
              that.$message.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.renewalData()
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })

    },
    // 删除角色
    deleteRole(id) {
      const that = this
      this.$confirm({
        content: '是否删除该角色？',
        onOk() {
          roleDelete({ roleId: id }).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.operationType = 'delete'
            that.renewalData()
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })
    },
    // 左侧--查看详情
    getDetail(item, type, id) {
      let text = ''
      this.listType = type
      if (id) this.selectId = id
      switch (type) {
        case 'member':
          this.parentData.memberTypeId = item.memberTypeId
          this.parentData.memberTypeName = item.memberTypeName
          this.parentData.typeName = item.typeName
          if (id === '') this.selectMember = item
          text = '无' + item.memberTypeName
          // eslint-disable-next-line no-case-declarations
          let bol = ''
          if (this.selectMember.typeName === 'enableUser') {
            bol = 1
          } else if (this.selectMember.typeName === 'disableUser') {
            bol = 0
          }
          this.selectedKeys = []
          this.getMemberUserList(bol)
          break
        case 'department':
          this.selectDepartment = item
          this.parentData.departmentId = item.departmentId
          this.parentData.departmentName = item.departmentName
          this.parentData.parentDepartmentId = item.parentDepartmentId
          this.deptForm = {
            isEnable: '',
            departmentId: item.departmentId,
            keyword: ''
          }
          this.getDeptUserList(this.deptForm)
          text = '当前部门无成员'
          break
        case 'role':
          this.selectRole = item
          if (id === '') this.selectRole = item
          text = '当前角色无成员'
          // eslint-disable-next-line no-case-declarations
          const data = {
            pageNum: this.pagination.current,
            pageSize: this.pagination.pageSize,
            param: { roleId: item.roleId.toString(), enterpriseId: '' },
            orders: [{ field: 'user_id', desc: false }]
          }
          roleUserList(data).then((res) => {
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            this.listData = res.data

            this.parentData.roleId = item.roleId
            this.parentData.roleName = item.roleName
            this.pagination.current = res.data['pageNum']
            this.pagination.pageSize = res.data['pageSize']
            this.pagination.total = res.data['total']
          })
          break
        default:
      }
      this.setLocaleText(text)
    },
    // 改变职位
    changePosition({ userId, key }) {
      const that = this
      const text = (key === '0' ? '成员' : '主管')
      this.$confirm({
        content: '是否将该成员的职位修改为' + text + '?',
        onOk() {
          const data = {
            departmentId: that.parentData.departmentId,
            userId: userId,
            isManage: key,
            enterpriseId: ''
          }
          setPosition(data).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.renewalData()
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })
    },
    // 查看详情
    async memberDetail(userId) {
      await this.getRoleList('', 'list')
      useDetail({ userId: userId, enterpriseId: '', }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.detailData = res['data']
        this.detailData.userRole = this.detailData.userRole.map((item) => {
          return item.roleId.toString()
        })
        this.detailVisible = true
      })
    },
    // 启用/禁用
    changeStatus({ isEnable, ids }) {
      const that = this
      const text = isEnable === 1? '启用' : '禁用'
      let content = ''
      switch (this.listType) {
        case "member":
          content = '是否' + text + '成员？'
          break
        case "department":
          content = '是否' + text + '部门成员？'
          break
        case "role":
          content = '是否' + text + '角色成员？'
          break
      }
      this.$confirm({
        content: content,
        onOk() {
          const data = {
            isEnabled: isEnable ? 1 : 0,
            userIds: ids,
            enterpriseId: ''
          }
          useDisable(data).then((res) => {
            if (res['code'] !== 0) {
              that.$message.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.renewalData()
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })
    },
    // 操作后更新数据
    renewalData() {
      if (this.keyword) {
        this.userListNum = ''
        this.searchData = {
          memberList: [],
          roleList: []
        }
        this.selectId = ''
        this.onSearch(this.keyword)
        if (this.operationType === 'delete') {
          this.getDetail({ memberTypeName: '相关成员', memberTypeId: '0', typeName: 'userAll' }, 'member', '1')
        }
      } else {
        if (this.operationType === 'delete') {
          if (this.activeKey === 'framework') {
            this.selectMember = this.memberTypeList[0]
            this.getDetail(this.selectMember, 'member', '')
          } else {
            this.getRoleList(this.keyword, 'detail')
          }
        } else {
          this.getUserAmount()
          switch (this.listType) {
            case 'member':
              this.getDetail(this.selectMember, 'member', '')
              break
            case 'department':
              this.getDetail(this.selectDepartment, 'department', this.selectDepartment.departmentId)
              break
            case 'role':
              this.getDetail(this.selectRole, 'role', this.selectRole.roleId)
              break
            default:
          }
        }
      }
    },
    // 移出成员
    shiftOut(userIds) {
      let data = {}
      const that = this
      switch (this.listType) {
        case 'department':
          this.$confirm({
            content: '是否将成员移出此部门？',
            onOk() {
              data = {
                id: that.parentData.departmentId,
                enterpriseId: '',
                userIds: userIds
              }
              moveDepartmentUser(data).then((res) => {
                if (res['code'] !== 0) {
                  that.$message.error(res['message'])
                  return
                }
                that.$antMessage.success(res['message'])
                that.renewalData()
              })
            },
            cancelText: '取消',
            onCancel() {
              that.$destroyAll()
            }
          })
          break
        case 'role':
          this.$confirm({
            content: '是否将成员移出此角色？',
            onOk() {
              data = {
                id: that.parentData.roleId,
                enterpriseId: '',
                userIds: userIds
              }
              moveRoleUser(data).then((res) => {
                if (res['code'] !== 0) {
                  that.$message.error(res['message'])
                  return
                }
                that.$antMessage.success(res['message'])
                that.renewalData()
              })
            },
            cancelText: '取消',
            onCancel() {
              that.$destroyAll()
            }
          })
          break
        default:
      }
    },
    // 重置密码
    resetPasswords(userId) {
      resetPassword({ userId, enterpriseId: ''  }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.newPassword = res.data
        this.passwordVisible = true
      })
    },
    // 添加子部门
    addChildren(data) {
      this.editType = false
      this.addDepartmentVisible = true
      this.departmentData = {
        parentDepartmentId: data.departmentId,
        departmentName: '',
        departmentId: '',
        userList: []
      }
    },
    // 编辑部门
    editDepartment(departmentId) {
      this.editType = true
      departmentManagerList({ departmentId }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.departmentData = res.data
        this.parentData.departmentId = res.data.departmentId
        this.parentData.departmentName = res.data.departmentName
        this.addDepartmentVisible = true
      })
    },
    // 删除部门
    deleteDepartment(departmentId) {
      const that = this
      this.$confirm({
        content: '是否删除该部门？',
        onOk() {
          departmentDelete({ departmentId }).then((res) => {
            if (res['code'] !== 0) {
              that.$antMessage.error(res['message'])
              return
            }
            that.$antMessage.success(res['message'])
            that.getDepartmentList('', '')
            that.operationType = 'delete'
            that.renewalData()
          })
        },
        cancelText: '取消',
        onCancel() {
          that.$destroyAll()
        }
      })
    },
    // 打开设置权限弹框
    setPermissions() {
      this.permissionsTreeData = permissionsTreeData
      this.permissionsVisible = true
    },
    // 关闭设置权限弹框
    closePermissions(bol) {
      this.permissionsVisible = bol
    },
    // 成员信息编辑类型切换
    changeEdit(bol) {
      this.isEdit = bol
    },
    // 编辑成功
    editSuccess(userId) {
      this.memberDetail(userId)
    },
    // 编辑成员--获取部门列表和角色列表
    editMember() {
      departmentBases({ enterpriseId: '' }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.deptList = res.data
      })
    },
    // 关闭详情弹框
    openDetail(bol) {
      this.detailVisible = bol
      this.renewalData()
    },
    // 添加成员成功
    addMemberSuccess(bol) {
      this.renewalData(bol)
      this.getUserAmount()
      this.addCloseChange(bol)
    },
    // 关闭详情弹框
    addCloseChange(bol) {
      this.addVisible = bol
    },
    // tabs 页切换
    callback(key) {
      switch (key) {
        case 'framework':
          this.getDetail(this.memberTypeList[0], 'member', '')
          this.selectedKeys = []
          break
        case 'role':
          this.getRoleList(this.keyword, 'detail')
          break
        default:
      }
    },
    // 选中节点
    changeSelectedKeys(selectedKeys) {
      this.selectMember = {}
      this.selectedKeys = selectedKeys
    },
    // 列表--分页、排序、筛选变化
    onChange(pagination) {
      this.pagination = pagination
      this.renewalData()
    },
    // 创建成员
    addMember() {
      departmentBases({ enterpriseId: '' }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.deptList = res.data
        this.getRoleList('', 'add')
      })
    },
    userAdd() {
      this.renewalData()
    },
    // 设置空状态
    setLocaleText(text) {
      this.localeText = {
        emptyText: (<div className='empty-wrap'>
          <a-icon type="cloud" style="fontSize: 50px" />
          <div>{text}</div>
        </div>)
      }
    },
    // 复制
    doCopy(text) {
      const that = this
      this.$copyText(text).then(function () {
        that.$antMessage.success('内容已复制到剪切板！')
      }, function () {
        that.$antMessage.error('复制失败，请尝试手动复制！')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  height:  calc(100vh - 160px);
  .container-header{
    text-align: left;
    display: flex;
    align-items: center;
    .header-title{
      font-size: 20px;
    }
  }
  .gutter-example{
    display: flex;
    //height:  calc(100vh - 190px);
    .gutter-box{
      //border: 1px solid #e9e9e9;
      height: 100%;
      //padding: 20px;
    }
    .gutter-box.left{
      //padding: 20px;
      width: 250px;
      min-width: 200px;
      .user-list{
        .list-item{
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          line-height: 40px;
        }
        &:hover{
          background-color: #e6f7ff;
        }
        &.active{
          background-color: #1890ff;
          color: #fff;
        }
      }
      .role-list{
        .role-list-item{
          height: 40px;
          line-height: 40px;
          &:hover{
            background-color: #e6f7ff;
          }
          &.active{
            background-color: #1890ff;
            color: #fff;
          }
        }
      }
      .left-item{
        height: 30px;
        line-height: 30px;
        &.item-member{
          display: flex;
          justify-content: space-between;
        }
        &:hover{
          background-color: #e6f7ff;
        }
        &.active{
          background-color: #1890ff;
          color: #fff;
        }
      }
      .search-list{
        text-align: left;
        .title{
          border-bottom: 1px solid #e8e8e8;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .gutter-box.right{
      flex: 1;
    }
  }
}

.p-0-15{
  padding: 0 15px;
}
.ant-tabs.tabs{
  height: calc( 100vh - 222px);
}
.modal-content{
  .content-password{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .password-value{
      font-size: 22px;
      font-weight: 600;
    }
  }
  .content-hint{
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }
}
</style>
